<template>
    <div>
        <div class="leisure_buttons_group"
             v-if=" $store.state.currentLeisureGameComp != leisureConst.LEISURE_COMP_BET365">
            <router-link tag="button" class="bw3 btn_minigame_comp"
                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_EOS}"
                         :to="{path:'/leisure/eospowerball1m', query: {t: new Date().getTime()}}">
                엔트리
            </router-link>
            <router-link tag="button" class="bw3 btn_minigame_comp "

                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_HS}"
                         :to="{path:'/leisure/hspowerball3m', query: {t: new Date().getTime()}}">
                하운슬로
            </router-link>
            <router-link tag="button" class="bw3 btn_minigame_comp "
                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_STAR_SADALI}"
                         :to="{path:'/leisure/ssd1', query: {t: new Date().getTime()}}">
                보스코어
            </router-link>
        </div>
        <div class="leisure_buttons_group"
             v-if=" $store.state.currentLeisureGameComp != leisureConst.LEISURE_COMP_BET365">
            <router-link tag="button" class="bw2 btn_minigame_comp "
                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_NAMED}"
                         :to="{path:'/leisure/npowerball3s', query: {t: new Date().getTime()}}">
                네임드
            </router-link>
            <router-link tag="button" class="bw2 btn_minigame_comp "
                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_GAMEMOA}"
                         :to="{path:'/leisure/gamemoaace', query: {t: new Date().getTime()}}">
                게임모아
            </router-link>
        </div>

        <!--            <router-link tag="button" class="bw3 btn_minigame_comp "-->
        <!--                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_STAR_SADALI}"-->
        <!--                         :to="{path:'/leisure/ssd1', query: {t: new Date().getTime()}}">-->
        <!--                별다리-->
        <!--            </router-link>-->
        <!--            <router-link tag="button" class="btn_minigame_comp bw4"-->
        <!--                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_JWCASINO}"-->
        <!--                         :to="{path:'/leisure/ssd1', query: {t: new Date().getTime()}}">-->
        <!--                JW-->
        <!--            </router-link>-->
        <!--            <router-link tag="button" class="btn_minigame_comp bw4"-->
        <!--                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_BET365}"-->
        <!--                         :to="{path:'/leisure/bet365_superleague', query: {t: new Date().getTime()}}">-->
        <!--                BET365-->
        <!--            </router-link>-->
        <!--            <router-link tag="button" class="bw4 btn_minigame_comp"-->
        <!--                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_TGAMEPOWERBALL}"-->
        <!--                         :to="{path:'/leisure/tgamepowerball', query: {t: new Date().getTime()}}">-->
        <!--                토큰파워볼-->
        <!--            </router-link>-->

    </div>


    </div>

</template>
<script>
    import sportsConst from "../../common/sportsConst";
    import leisureConst from "../../common/leisureConst";

    export default {
        name: "LeisureGameCompLinks",
        data() {
            return {
                leisureConst: leisureConst,
            }
        },
        methods: {
            commingsoon() {
                alert('준비중입니다')
            },
        }
    }
</script>

<style scoped>

</style>